import styles from "../css/app.pcss";
import { tns } from "tiny-slider/src/tiny-slider";
var SimpleLightbox = require("simple-lightbox");
var $ = require("jquery");
var Pjax = require('pjax');
import Autocomplete from "@trevoreyre/autocomplete-js"

// App main
const main = async () => {
  // Import our CSS
  // const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
  // Async load the vue module
  // const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
  // Create our vue instance
  // const vm = new Vue.default({
  //     el: "#app",
  //     components: {
  //         'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
  //     },
  //     data: {
  //     },
  //     methods: {
  //     },
  //     mounted() {
  //     },
  // });
};
// Execute async function
main().then(value => {

  $(document).on( "click", "#searchToggle", function(event) {
    $('.searchbox').toggleClass('hidden');
    $( "#q" ).focus();
    console.log('show toggle')
  });
  $(document).on( "click", ".navtoggle", function(event) {
    $(this).parent().toggleClass('show');
    $(this).parent().toggleClass('hide');
    console.log('show nav toggle')
  });



  document.getElementById("hamburger").onclick = function() {
    document.getElementById("navbar").classList.toggle("opened");
    document.getElementById("hamburger").classList.toggle("open");
  };

  document.addEventListener("DOMContentLoaded", function() {
    // Init Pjax instance
    Pjax = new Pjax({
      elements: [".pjax-switch"],
      selectors: [".crumbs", ".product", ".prod-description"],
      cacheBust: false
    });
    console.log("Pjax initialized.", Pjax);
  
    // Init page content
  });
  function handleSend(e) {
    /**
     * before using fadeIn, you have to implement it
     * as the prototype method of HTMLElement
     */
    $(".crumbs, .product, .prod-description").fadeTo( "fast", 0.33 );

  }
  function handleComplete(e) {
    /**
     * before using fadeOut, you have to implement it
     * as the prototype method of HTMLElement
     */
    // document.querySeletor('loading').fadeOut();
    init();
    $(".crumbs, .product, .prod-description").fadeTo( "slow", 1 );

}
  /** listen to sending requests */
document.addEventListener('pjax:send', handleSend, false);
/** listen to ending requests */
document.addEventListener('pjax:complete', handleComplete, false);

  function doLightboxInit() {
    var lightbox = new SimpleLightbox({ elements: ".gallery .gallery-image, .modal-image" });
  }

  function doBannerSliderInit() {  
    var sliderArray = document.querySelectorAll(".banner-slider");
    sliderArray.forEach(function(el) {
      tns({
        container: el,
        mode: "gallery",
        mouseDrag: true,
        controls: true,
        prevButton: ".prev-button",
        nextButton: ".next-button",
        nav: false,
        navPosition: "bottom",
        items: 1,
        autoplay: true,
        autoplayButtonOutput: false
      });
    });
  }
  function doProductSliderInit() {
    var productImageArray = document.querySelectorAll(".product-images");
    productImageArray.forEach(function(el) {
      tns({
        container: el,
        mode: "gallery",
        mouseDrag: true,
        controls: false,
        nav: true,
        navPosition: "bottom",
        items: 1,
        autoplay: true,
        autoplayButtonOutput: false,
        navAsThumbnails: true,
        navContainer: '.product-thumbs'
      });
    });
  }
  function doLogoSliderInit() {
    var logoImageArray = document.querySelectorAll(".logo-slider");
    logoImageArray.forEach(function(el) {
      tns({
        container: el,
        items: 3,
        loop: true,
        autoplay: true,
        autoplayHoverPause: false,
        autoplayButtonOutput: false,
        controls: false,
        nav: false,
        speed: 2000,
        autoplayTimeout: 0,
        preventActionWhenRunning: true,
        mouseDrag: true,
        responsive: {
          400: {
            items: 4
          },
          700: {
            items: 8
          }
        }
      });
    });
  }

  function doTabsInit() {
    $('.tab_content').hide();
    $('.tab_content:first').show();
    $('.tabs li:first').addClass('active');
    // $('.tabs li').click(function(event) {
  }
  $(document).on( "click", ".tabs li", function(event) {
    event.preventDefault();
    $('.tabs li').removeClass('active');
    $(this).addClass('active');
    $('.tab_content').hide();
    
    var selectTab = $(this).find('a').attr("href");
    
    $(selectTab).fadeIn();
  });

  // on change of variant radios
  $(document).on( "change", ".purchasableId", function(event) {
    // hide any purchaseInfo panels
    $(".purchasableInfo").hide();
    // get the id to build reference to this ones panel
    let thisid = $('input[name="purchasables[0][id]"]:checked').data('info')
    // toggle its visibility
    $("#"+(thisid)).toggle();
    // also register a click on it's thumbnail if it exists
    $("#thumb-"+ thisid).trigger('click');
    // now update the price
    updatePrice();
    // and update the SKU text
    updateSKUText();
  });

  // variants thumbnail click
  $(document).on( "click", ".variantclick", function(event) {
    // hide any purchaseInfo panels
    $(".purchasableInfo").hide();
    // show this info panel
    $("#"+($(this).data('info'))).toggle();
    // select this variant in the purchasables list
    $("input[name='purchasables[0][id]'][value='"+$(this).data('value')+"']").prop('checked', true);
    //update price text
    updatePrice();
    //update SKu text
    updateSKUText();
  });

  //run functions when addon dropdowns are selected
  $(document).on( "change", ".addonId", function() {
    showInfoPanels();
    updatePrice();
    updateSKUText();
  });

  function showInfoPanels(){
    //hide all panels to start
    $(".addonInfo").hide();
    // loop thorugh each selected options and show it's relevant panel
    $(".addonId").find(':selected').each(function () {
      // $('.customtextbox').val('');
      $("#"+($(this).data('info'))).toggle();
    })
  }

  // qty component
  $(document).on( "click", ".num-in span", function() {
  // $('.num-in span').click(function () {
    var $input = $(this).parents('.num-block').find('input.in-num');
    if($(this).hasClass('minus')) {
      var count = parseFloat($input.val()) - 1;
      count = count < 1 ? 1 : count;
      if (count < 2) {
        $(this).addClass('dis');
      }
      else {
        $(this).removeClass('dis');
      }
      $input.val(count);
    }
    else {
      var count = parseFloat($input.val()) + 1
      $input.val(count);
      if (count > 1) {
        $(this).parents('.num-block').find(('.minus')).removeClass('dis');
      }
    }
  
    $input.change();
    updatePrice();
    return false;
  })

  // change 
  $(document).on( "keyup", ".in-num", function(event) {
    if ($('.in-num').val() > 0) {
      console.log('changed');
      updatePrice();
    } else {
      $('.in-num').val(1)
    }
  });

  function updateSKUText() {
    var newSKU = $("input[name='purchasables[0][id]']:checked").data('variant-sku');
    if (newSKU) {
      $(".chosenvariant-sku").text(newSKU);
    }

  }

  function updatePrice() {
    // set vars
    var originalPrice 
    originalPrice = parseFloat($("input[name='purchasables[0][id]']:checked").data('variant-price'));
    if (isNaN(originalPrice)) {
      originalPrice = parseFloat($("input[name='singlevariantprice").val())
    }
    
    var additionsTotal
    additionsTotal = 0
    var runningPrice
    runningPrice = 0
    var qty = $('input.in-num').val()

    if ($('#bulkpricing').length) {
      const bulkPricingVal = $('#bulkpricing').val();

      const arrBulkPricing = JSON.parse(bulkPricingVal)
      runningPrice = Number.parseFloat((originalPrice * qty)).toFixed(2);

      arrBulkPricing.forEach((item, index, array) => {
        console.log('qty', qty);
        console.log('minQuantity', item['minQuantity']);
        console.log('maxQuantity', item['maxQuantity']);
        console.log('price', item['price']);
        if ((Number(qty) >= Number(item['minQuantity']) && Number(qty) <= Number(item['maxQuantity'])) || (Number(qty) >= Number(item['minQuantity']) && (item['maxQuantity'] === '')))
        {
          runningPrice = Number.parseFloat((item['price'] * qty)).toFixed(2);
          console.log("got a runningPrice", runningPrice)
        } 
      })
    } else {
      runningPrice = Number.parseFloat((originalPrice * qty)).toFixed(2);
    }
    

    // gather additional dropdowns totals
    $(".addonId:checked").each(function () {
      additionsTotal += $(this).data('added-price');
    })
    // sum them onto the original price
    runningPrice = Number.parseFloat(runningPrice + additionsTotal).toFixed(2)
    //set the totalprice data attr
    $(".total-price").data('running-price', runningPrice)
    // calculate vat price
    const vatprice = Number.parseFloat(runningPrice * 1.2).toFixed(2)
    //display the new price
    $(".total-price").text('£' + $(".total-price").data('running-price').toString());
    $(".totalvat-price").text('£' + (vatprice).toString());

    // now do the same for sale price display if it's onsale
    // sum them onto the original saleprice
    if ($('.sale-price').length){
      var originalSalePrice 
      originalSalePrice = parseFloat($('.sale-price').data('original-saleprice'));
      var runningSalePrice
      runningSalePrice = 0
      runningSalePrice = originalSalePrice + additionsTotal
      //set the totalprice data attr
      $(".sale-price").data('running-saleprice', runningSalePrice)
      //dislay the new price
      $(".sale-price").text('£' + $(".sale-price").data('running-saleprice').toString());
    }
  }

  // faveheart clicks to ajax

  $(document).on( "click", "a.faveheart", function(event) {
    event.preventDefault();
    // get product id
    var data = {
      elementId: $('#AJproductID').val()
    }
    // get csrf token
    data[window.csrfTokenName] = window.csrfTokenValue;
    // ajax call to toggle wishlist item 
    $.ajax({
        type: 'POST',
        url: 'actions/wishlist/items/toggle',
        data: data,
        dataType: 'json'
    }).done(function(data) {
      // on done toggle the red heart on or off
      $('.faveheart').toggleClass('inlist')
      // get the curren displayed total of faves
      let favecount = Number($('.favelink .badge').html())
      // if we're adding the item increment the total
      if ($('.faveheart').hasClass('inlist')) {
        favecount++;
      } else {
      // else decrement it
        favecount--;
      }
      // uopdate the span badge to show the new total
      $('.favelink .badge').html(favecount)
    });
  });


  // search button go
  $(document).on( "click", ".searchgo", function(event) {
    window.location.href = `/search?q=${$('input[name=q]').val()}`;
  });

  function init() {
    doLightboxInit();
    doBannerSliderInit();
    doProductSliderInit();
    doLogoSliderInit();
    doTabsInit();
  }

  init();


  new Autocomplete('#autocomplete', {
    
    // Search function can return a promise
    search: input => {
      const url = `/api/search.json?q=${encodeURI(input)}`

      return new Promise(resolve => {
        if (input.length < 3) {
          return resolve([])
        }

        fetch(url)
          .then(response => response.json())
          .then(data => {
            console.log('data', data)
            resolve(data.data)
          })
      })
    },
    autoSelect: true,
    
    // Control the rendering of result items.
  // renderResult: (result, props) => `
  //   <li ${props}>
  //     <div class="title">
  //       ${result.title}
  //     </div>
  //     <div class="urlsnippet">
  //       ${result.url}
  //     </div>
  //   </li>
  // `,
    // We want to display the title
    getResultValue: result => result.title,

    // Open the selected article in
    // a new window
    onSubmit: result => {
      window.location.href = encodeURI(result.url)
    }
  })
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
